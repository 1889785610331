:root {
  --main: #fcb721;
  --main-tp: #fcb721a4;
  --main-lg: #fcb92746;
  --sub: #fd8628;
  --sub-tp: #fd8628a4;
  --sub-lg: #fd842846;
}

#loadingPage {
  background: whitesmoke;
  height: 100vh;
  padding: 20px;
}

#loadingPage {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 54%);
  background-size: 1000px 640px;
  position: relative;
  min-height: 10px;
}
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: var(--main);
  letter-spacing: 0.2em;
}

.loader::before,
.loader::after {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  background: var(--main);
  position: absolute;
  animation: load 1s infinite alternate ease-in-out;
}

.loader::before {
  top: 0;
}

.loader::after {
  bottom: 0;
}

@keyframes load {
  0% {
    left: -10px;
    height: 25px;
    width: 10px;
  }
  50% {
    height: 8px;
    width: 40px;
  }
  100% {
    left: 245px;
    height: 25px;
    width: 10px;
  }
}

.wrapper {
  padding-top: 57px !important;
  /* position: relative; 
  z-index: 1; */
}
.contactConWrapper,
section#contact #OH {
  position: relative;
  z-index: 0;
}
.wrapper#EShop {
  padding-top: 0 !important;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
}
.wrapper#DigitalDisplay {
  padding: 0 50px;
}
section#contact .activeDay {
  display: none;
}

.video-react {
  padding-top: 280px !important;
}
.sortableHelper {
  z-index: 9999999;
}
.contactSocialMedia.menu_0 {
  z-index: 8;
}
section {
  padding: 0 4em !important;
}
section#aboutUs {
  padding: 0 !important;
}
section.dark h2 {
  color: white !important;
}
.textareaFieldWrapper,
.textareaFieldWrapper .textareaField {
  max-width: unset;
}
.buttonWrapper {
  margin: 0;
}

.changeData {
  color: white;
  text-decoration: none;
}
#testMode {
  height: 100%;
  z-index: 9999;
  color: white;
  transition: 0.25s;
  overflow: auto;
}
#testMode:hover {
  left: 0;
}
#testMode .testBlock {
  padding: 25px;
}
#testMode .testBlock:nth-child(odd) {
  background-color: var(--grey-80);
}
#testMode .testBlock:nth-child(odd) .testTitle {
  border-bottom: 1px solid var(--grey-70);
}
#testMode .testBlock:nth-child(odd) li {
  border: 1px solid var(--grey-70);
}
#testMode .testBlock:nth-child(odd) li:hover {
  background-color: var(--grey-70);
}
#testMode .testTitle {
  padding-bottom: 10px;
  text-align: center;
  border-bottom: 1px solid var(--grey-80);
  margin-bottom: 10px;
}
#testMode select {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: none;
}
#testMode span {
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
#testMode ul {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  font-size: 14px;
}
#testMode li {
  cursor: pointer;
  flex: 1 1 auto;
  display: inline-block;
  text-align: center;
  border: 1px dotted var(--grey-80);
  padding: 5px 10px;
  text-transform: capitalize;
}
#testMode li:hover {
  background-color: var(--grey-80);
}
section#digitalDisplay {
  padding: 80px 50px 50px;
}
section#currentOffers .sectionWrapper {
  padding: 0 !important;
}
section#currentOffers.slideshow .slick-slider {
  width: 100%;
  height: 510px;
  padding-top: 0;
  margin: 0 !important;
}
section#currentOffers.slideshow .co {
  height: 440px !important;
}
section#currentOffers.slideshow .slick-list {
  height: 440px !important;
}
section#currentOffers.slideshow .slick-slider .slick-list::after,
section#currentOffers.slideshow .slick-slider .slick-list::before {
  z-index: -1 !important;
}

/***********************************************/
/*************** Main color theme **************/
/***********************************************/

section#jobApplication.temp-1 .card-text .card__title {
  color: var(--sub);
}

section#newsArticles.temp-1 .article a {
  color: var(--main);
}

/***********************************************/
/********************* Main ********************/
/***********************************************/

h1.temp-1 {
  font-size: 30px;
  text-align: center;
  margin: 0;
}
h1.temp-1 span {
  background-color: white;
  border-radius: 5px;
  padding: 5px 15px;
}
h1.temp-2 {
  font-size: 50px;
  text-align: center;
  margin: 0;
  color: white;
  font-weight: 100;
}
h2 {
  padding-top: 1em;
  padding-bottom: 1em;
  color: var(--grey-90) !important;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
}
h3 {
  font-size: 20px;
  margin-top: 0;
}
h4 {
  font-size: 15px;
}
p {
  margin-top: 0;
  margin-bottom: 0;
}
p:last-child {
  margin-bottom: 0;
}

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
}
input,
textarea {
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  resize: vertical;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  outline: none;
  outline-width: thin;
}
/* input[type="submit"] {
  width: 100%;
  max-width: 150px;
  display: block;
  box-shadow: none;
  text-transform: uppercase;
} */
footer h2 {
  text-align: left;
  font-size: 35px;
  margin-bottom: 50px;
}
section#team p {
  text-align: center;
  padding-bottom: 1em;
}

p,
section#team .teamIntro {
  font-size: 17px;
  font-weight: 200;
  line-height: 30px;
}
section#aboutUs.dark .aboutContent h2,
section#team.dark h2 {
  color: var(--grey-50) !important;
}
section#aboutUs.main .aboutContent h2,
section#aboutUs.sub .aboutContent h2,
section#team.main h2,
section#team.sub h2 {
  color: white;
}
section#contact h2 {
  padding: 0 0 50px 0;
}
section#contact {
  padding: 0 !important;
}

section#jobApplication .card__container .card,
section#newsArticles .container::before,
section#newsArticles .article,
section#ourWork .item,
section#socialNews .sn_card,
section#review .card,
section#quote .container {
  box-shadow: 1px 1px 10px rgba(39, 39, 39, 0.247) !important;
}

/****** news ******/

section#newsArticles .container {
  grid-template-columns: repeat(auto-fill, minmax(370px px, 370px));
}
section#newsArticles {
  padding: 0;
  margin: 0;
}
section#newsArticles .sectionWrapper h2 {
  padding: 1em 0;
  margin: 0;
}
section#newsArticles.temp-4 .sectionWrapper {
  justify-content: center;
  display: flex;
  width: 100%;
}
section#newsArticles.temp-3 .article h3 {
  height: 85px;
}

/*** social news ***/

section#socialNews.light {
  background-color: var(--grey-5) !important;
}
section#socialNews h2 {
  padding: 1em 0;
  margin: 0;
}
section#socialNews .sectionWrapper {
  padding: 0 4em;
}
section.news-details {
  padding: 2em 0 0;
}
section.news-details .news-details__content h2 {
  padding: 0 0 1em;
  font-size: 2.5em;
  text-align: left;
  font-weight: 700;
  color: var(--main);
  margin: 0;
}

section#newsArticles.temp-2 .article {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section#newsArticles.temp-2 .article h3 {
  width: 90%;
}

section#newsArticles.temp-2 .article p,
section#newsArticles.temp-2 .article #link,
section#newsArticles.temp-2 .article h3 {
  left: 0;
}

/**** jobApplication ****/

section#jobApplication {
  padding: 2em;
}
section#jobApplication.temp-2 .card-text .card__title,
section#jobApplication.temp-2 .card-text .card__link {
  text-decoration: underline;
  color: var(--sub);
  cursor: pointer;
}
section#jobApplication .card-text .card__title,
section#jobApplication .card-text .card__link {
  cursor: pointer;
  word-wrap: break-word;
}

section#jobApplication .card-text .card__link {
  font-size: unset;
  font-weight: unset;
  line-height: unset;
  color: var(--sub);
}

section#jobApplication.temp-1 .card-text .card__title {
  font-size: 16px;
}

section h2 {
  font-weight: 200 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  font-size: 50px;
}

/**** social ****/

section#social {
  display: flex;
  flex-direction: column;
  align-items: center;
}
section#social h2 {
  padding: 0;
}
section#social h2.multiple {
  display: none;
}
section#social section {
  padding: 0em !important;
}
section#social .tab-container {
  margin-left: unset;
  display: unset;
  display: flex;
  width: 700px;
  margin-bottom: 1.5em;
}
section#social .tab-container.multiple .tab.face {
  clip-path: polygon(100% 0, 85% 100%, 0 100%, 0 0) !important;
  border-radius: 5px 0 0 5px;
  right: -27px;
  position: relative;
}
section#social .tab-container.multiple .tab.insta {
  clip-path: polygon(15% 0, 100% 0, 100% 100%, 0 100%) !important;
  border-radius: 0 5px 5px 0;
  left: -27px;
  position: relative;
}
section#social .tab-container.multiple .tab.insta,
section#social .tab-container.multiple .tab.face {
  background-color: unset;
  color: var(--grey-80);
  background-color: var(--grey-20);
  width: 50%;
  height: 40px;
  margin: 0em;
  cursor: pointer;
  transition: 0.2s;
  font-size: 14px;
  text-transform: uppercase;
}
section#social .tab-container.single .tab.insta,
section#social .tab-container.single .tab.face {
  width: 100%;
  display: none;
}
section#social .tab-container .tab.active .icon {
  color: var(--grey-10);
  transition: 0.5s;
}
section#social .tab-container .tab.face.active {
  color: white;
  background-color: var(--facebook);
  padding: 0.2em;
}
section#social .tab-container .tab.insta.active {
  color: white;
  background: linear-gradient(30deg, #8a3ab9 -50%, #fbad50 6%, #cd486b 96%, #4c68d7);
}
section#social #feed-face .slick-slider,
section#social #feed-insta .slick-slider {
  width: 90vw;
  padding: 1em;
}
section#social #feed-face .post .slick-slider,
section#social #feed-insta .post .slick-slider {
  width: 100%;
  padding: 0em;
}
section#social .tab-container .tab.insta.active:hover,
section#social .tab-container .tab.face.active:hover {
  color: var(--grey-10);
}
section#social .tab-container .tab.insta:hover {
  color: white;
}
section#social .tab-container .tab.face:hover {
  color: white;
}
section#social .feed-data {
  display: flex;
  align-items: center;
  justify-content: center;
}
section#social #feed-insta .feed-data .slick-dots {
  bottom: -40px;
}
section#social #feed-face .feed-data .post .slick-prev {
  left: 5px;
}
section#social #feed-face .feed-data .post .slick-next {
  right: 5px;
}
section#social #feed-face .feed-data .slick-prev,
section#social #feed-insta .feed-data .slick-prev {
  left: -17px;
  color: red;
  height: 30px;
  width: 30px;
}
section#social #feed-face .feed-data .slick-next,
section#social #feed-insta .feed-data .slick-next {
  right: -17px;
  color: red;
  height: 30px;
  width: 30px;
}
section#social #feed-face .feed-data .slick-prev:before,
section#social #feed-face .feed-data .slick-next:before,
section#social #feed-insta .feed-data .slick-prev:before,
section#social #feed-insta .feed-data .slick-next:before {
  content: none;
}

section#social #feed-face .feed-data .post .slick-next:before,
section#social #feed-insta .feed-data .post .slick-next:before {
  content: "→";
}
section#social #feed-face .feed-data .post .slick-prev:before,
section#social #feed-insta .feed-data .post .slick-next:before {
  content: "←";
}
section#social #feed-insta .post-image__container {
  background-color: unset;
}
section#social #feed-face .post {
  height: 500px;
  justify-content: unset;
  background-color: white;
  border: 1px solid var(--grey-20);
  box-shadow: 5px 5px 10px rgb(0 0 0 / 8%);
  padding: 0 1.5em;
}
section#social #feed-face .post .post-body {
  width: 100%;
}
section#social #feed-face .post .post-header {
  width: 100%;
  padding: 0em;
}
section#social #feed-face .post .post-image__container {
  max-height: 280px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 5%);
  border: 1px solid var(--grey-10);
  width: 100%;
  min-height: 280px;
  position: relative;
}
#feed-face .post-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100% !important;
}
section#social #feed-face .sn_logo {
  position: unset;
  border-radius: 20px;
}
#feed-face .feed-data .slick-dots,
#feed-insta .feed-data .slick-dots {
  flex-wrap: wrap;
}
#feed-face .feed-data .slick-dots li,
#feed-insta .feed-data .slick-dots li {
  width: 30px;
  display: flex;
  justify-content: center;
  padding: 0 23px;
  margin: 0;
}
#feed-face .feed-data .slick-dots li button,
#feed-insta .feed-data .slick-dots li button {
  background-color: white;
  line-height: unset;
  width: 25px;
  height: 23px;
}
#feed-face .feed-data .slick-dots li button::before,
#feed-insta .feed-data .slick-dots li button::before {
  position: unset;
  line-height: unset;
  font-size: 10px;
}
#feed-face .feed-data .slick-dots li::after,
#feed-insta .feed-data .slick-dots li::after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 1px solid var(--grey-30);
  width: 100%;
  left: 0;
  z-index: -1;
  cursor: default;
}
section#social #feed-face .post .slick-slider {
  width: 100%;
}
section#social #feed-face .post .slick-list::before {
  content: none;
}
section#social #feed-face .post .slick-list::after {
  content: none;
}
section#social #feed-face .video-react-controls-enabled {
  max-height: 280px !important;
}
section#social #feed-face .post-container {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 1em;
}
section#social #feed-face .post-body .post-body__title {
  margin: 1em 0 0;
  padding: 0;
}
section#social #feed-face .post-body a {
  text-decoration: none;
  color: black;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
section#social #feed-face .post-body a .icon {
  height: 14px;
}
section#social #feed-insta .overlay .overlay-caption {
  font-size: 14px;
}
section#social #feed-face .post .icon {
  display: flex;
  width: 22px;
  justify-content: center;
  align-items: center;
}
.ReactModal__Content .modal-file {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 600px;
}
.ReactModal__Content .modal-data {
  min-width: 25vw;
}
.ReactModal__Content .modal-file .slick-prev {
  left: 75px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 5%);
}
.ReactModal__Content .modal-file .slick-next {
  right: 75px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 5%);
}

/*----- insta mobile -----*/

section#social.mobile #feed-insta .post {
  width: 250px;
  height: 600px;
}
section#social.mobile #feed-insta .post-image__container img {
  height: 250px;
}
section#social.mobile #feed-insta .post-image__container {
  height: 100%;
  flex-direction: column;
}
section#social.mobile #feed-insta .overlay {
  opacity: 1;
  position: relative;
  flex-direction: column-reverse;
  background-color: white;
  color: var(--grey-90);
}
section#social.mobile #feed-insta .overlay-caption {
  max-height: 245px;
  text-overflow: ellipsis;
  overflow: hidden;
}

/**** ourwork ****/

section#ourWork.temp-2 .item .item-content h2:after,
section#ourWork.temp-2 .item .item-content h2:before {
  background-color: var(--grey-5);
}
section#ourWork {
  padding-top: 2em;
}

section#ourWork .item {
  background-color: var(--grey-5);
}
section#ourWork.light {
  background-color: white;
}

section#aboutUs.temp-3 .aboutContent {
  margin: 6em 6em 0;
  padding: 50px 50px 0;
}

/**** review ****/

section#review h2 {
  text-align: center;
  padding: 1em 0;
  margin-bottom: 0px !important;
}
section#review {
  background-color: white !important;
  padding-bottom: 2em !important;
}
section#review.dark {
  background-color: var(--grey-90) !important;
  padding-bottom: 2em !important;
}
section#review.temp-1 h3 {
  text-align: center;
  margin: 0.5em;
}
section#review.temp-1 p {
  height: unset;
}
section#review.temp-2 {
  display: flex;
  flex-direction: column;
}
section#review.temp-2 .reviewslist #box {
  height: 400px;
}
section#review.temp-2 .reviewslist {
  width: 100%;
}
section#review.temp-2 .reviewslist #box1 {
  padding-top: 1em;
}
section#review.temp-2 .container {
  overflow: hidden;
  width: 1060px;
}
section#review.temp-2 p {
  font-size: 1em;
}
section#review.temp-2 .reviewslist #box2 {
  margin-top: 170px;
}
section#review.temp-2 .slick-track {
  display: flex;
}
section#review.temp-2 .slick-slide {
  width: 530px;
}

section#review .read-more {
  padding-left: 10px;
  font-weight: 700;
  color: var(--main) !important;
  display: none;
}
section#review .extra-text {
  display: inline;
}
section#review .read-less {
  padding-left: 10px;
  font-weight: 700;
  color: var(--main) !important;
  font-size: 14px;
}
section#review.temp-1 a::after {
  max-width: 60px;
  background-color: var(--main);
}

/**** quote ****/

div#Quote h1 {
  text-align: center;
  background-color: var(--grey-5);
  padding: 1em 0;
}
div#Quote h2 {
  background-color: var(--grey-5);
}
section#quote {
  padding: 0 2em 2em;
  padding-bottom: 40px !important;
}
section#quote .slick-track {
  display: flex;
}
section#quote.temp-1 .container {
  height: 150px;
}

/**** current-offer ****/

section#currentOffers .current_offers_toolbar {
  display: none;
}

section#currentOffers .slick-prev,
section#currentOffers .slick-next {
  color: var(--main);
}

section#currentOffers.slideshow .slick-slider .slick-arrow.slick-next {
  right: -12px;
}
section#currentOffers.slideshow .slick-slider .slick-arrow.slick-prev {
  left: -12px;
}

/**** team ****/

section#team.temp-2 h4 {
  font-size: 15px;
}

/**** e-shop ****/

section#e-shop {
  background-image: linear-gradient(rgba(202, 202, 202, 0.75), rgba(202, 202, 202, 0.75)), url("./library/images/webshop-background.png");

  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}
section#e-shop.dark {
  background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("./library/images/webshop-background.png");
}
section#e-shop.marble {
  background-image: url("./library/images/webshop-background.png");
}
section#e-shop.marble h2 {
  color: var(--grey-100) !important;
  font-weight: 900 !important;
}
section#e-shop.marble figure {
  width: 200px;
  height: 150px;
  position: relative;
}
section#e-shop.marble figure img {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section#e-shop.marble figure.orderbutton {
  width: 80px;
}

section#e-shop.panos {
  background-color: #f9e46b;
  background-image: none;
  margin-top: 50px;
}
section#e-shop.panos h2 {
  color: var(--grey-100) !important;
  font-weight: 900 !important;
  font-family: "Roboto slab", serif;
  text-transform: uppercase;
}
section#e-shop.panos figure {
  width: 450px;
  height: 250px;
  position: relative;
}
section#e-shop.panos figure img {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section#e-shop.panos figure.orderbutton {
  width: 120px;
}

@media (max-width: 640px) {
  section#e-shop.panos figure {
    width: 300px;
  }
}

section#e-shop.nederename {
  background-image: url("./library/images/webshop-background.png");
  background-size: cover;
  flex-direction: row;
  max-height: 400px;
}
section#e-shop.nederename figure {
  order: 0;
  max-width: 50%;
  min-height: 400px;
  position: relative;
  width: 50%;
}
section#e-shop.nederename figure img {
  width: 100%;
  max-width: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section#e-shop.nederename .content {
  order: 1;
  width: 50%;
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
section#e-shop.nederename h2 {
  color: black;
  font-family: "Veneer", sans-serif;
  font-size: 4em;
  font-weight: 400 !important;
  text-transform: uppercase;
  text-align: center;
}
section#e-shop.nederename .content a {
  color: #f29382;
  font-family: "Roboto", sans-serif;
  font-size: 2em;
  font-weight: 400 !important;
  text-transform: uppercase;
  text-align: center;
  border: 2px solid #f29382;
  text-decoration: none;
  padding: 10px;
  transition: 0.2s;
}
section#e-shop.nederename .content a:hover {
  background-color: #f29382;
  color: white;
}
@media (max-width: 900px) {
  section#e-shop.nederename {
    flex-direction: column;
    max-height: unset;
  }
  section#e-shop.nederename figure {
    min-height: 280px;
    width: 100%;
  }
  section#e-shop.nederename figure img {
    width: unset;
    height: 100%;
  }
  section#e-shop.nederename .content {
    width: 100%;
    gap: 30px;
    padding: 15px;
  }
  section#e-shop.nederename .content h2 {
    font-size: 2rem;
    margin: 0 !important;
  }
}

section#e-shop h2 {
  color: black !important;
  margin-top: 40px;
  margin-bottom: 60px !important;
}
section#e-shop.dark h2 {
  color: white !important;
}

section#e-shop .e-shop-button {
  width: calc(50% - 2px);
  max-width: 550px;
  margin: 0 25px 25px 25px;
  position: relative;
  height: 80px;
  background-color: transparent;

  border: 1px solid black;
  color: black;
  border-radius: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}
section#e-shop.dark .e-shop-button {
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
}
section#e-shop .e-shop-button:hover {
  background-color: white;
  border: 1px solid white;
  color: var(--main);
}
section#e-shop .e-shop-button__container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/**** floorplan ****/

section#floorplan {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 60px !important;
}
section#floorplan h2 {
  padding: 0 0 20px !important;
}
section#floorplan .magnifying-glass {
  border: none;
  background: white no-repeat;
}
section#floorplan .floorplan-image__container {
  width: 60vw;
}

/**** about us ****/

section#aboutUs {
  align-items: flex-end;
}
section#aboutUs.temp-1 {
  align-items: unset;
}
section#aboutUs.temp-3 .aboutImg::after {
  content: "";
  width: 75%;
}
section#aboutUs.temp-3 .aboutImg::before {
  content: "about us";
  width: 75%;
  text-align: center;
}

section#aboutUs.temp-3 {
  align-items: unset;
}
section#aboutUs.temp-4 h2 {
  font-size: 1em;
  padding-top: 0;
}
section#aboutUs.temp-4 p {
  font-size: 1.1em;
}
section#aboutUs.temp-2.dark .aboutContent h2 span,
section#aboutUs.temp-2.dark .aboutContent p,
section#aboutUs.temp-2.main .aboutContent h2 span,
section#aboutUs.temp-2.main .aboutContent p,
section#aboutUs.temp-2.sub .aboutContent h2 span,
section#aboutUs.temp-2.sub .aboutContent p {
  color: var(--grey-90) !important;
}
section#aboutUs.temp-2.light .aboutContent h2 span,
section#aboutUs.temp-2.light .aboutContent p {
  color: var(--grey-10) !important;
}

section#aboutUs.temp-4.light .aboutContent {
  background-color: var(--sub-tp);
}
section#aboutUs.temp-4.dark .aboutContent {
  background-color: var(--main-tp);
}
section#aboutUs.dark .aboutContent h2 span,
section#aboutUs.main .aboutContent h2 span,
section#aboutUs.sub .aboutContent h2 span,
section#aboutUs.main .aboutContent p,
section#aboutUs.sub .aboutContent p,
section#aboutUs.dark .aboutContent p {
  color: white !important;
}

/**** login ****/

#Socials section {
  padding: 4em 4em 6em !important;
  background-color: unset;
}

/**** vacature ****/

div#Vacature {
  margin-bottom: 4em;
}
section#vacature {
  margin: 0;
  padding: 0 !important;
  margin-bottom: 2em !important;
}
.vacature-body {
  display: flex;
  justify-content: center;
  width: 100%;
}
section#vacature .vacature-content h3 {
  text-align: center;
  margin-bottom: 0em;
}
section#vacature #jobApplication {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 !important;
  width: 100%;
  justify-content: center;
}

section#vacature .vacature-content {
  width: 66%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
section#vacature .vacature-content .form__field,
section#vacature .vacature-content .inputField {
  max-width: unset !important;
}
section#vacature .jobFormWrap .birthdate div,
section#vacature .jobFormWrap .motivatie div {
  padding-top: 10px;
  padding-bottom: 0;
  position: relative;
}
section#vacature .vacature-content .birthdate p,
section#vacature .vacature-content .motivatie p {
  position: absolute;
  font-size: 13px;
  top: -5px;
  left: 5px;
  z-index: 1;
}
section#vacature .vacature-content .motivatie p {
  top: 15px;
  left: 10px;
  transition: 0.2s;
}
section#vacature .vacature-content .birthdate p::after,
section#vacature .vacature-content .motivatie p::after {
  background: white;
  content: "";
  position: absolute;
  top: 15px;
  width: 100%;
  left: 0;
  height: 1px;
  z-index: -1;
}
section#vacature #ja_message:focus {
  border: 1px solid var(--main);
  outline: none;
}
/* .form__field:focus ~ .form__label */
section#vacature #ja_message:focus + .motivatieLabel {
  top: 0;
  left: 10px;
}

section#vacature .motivatie.active .motivatieLabel {
  top: 0;
  left: 10px;
}

section#vacature h5 {
  padding-bottom: 15px;
  color: var(--main);
  font-size: 14px;
}
section#vacature .job_choice {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
section#vacature .checkBox.active .checkboxBox .checkboxBoxSelected {
  background-color: var(--main);
}
section#vacature .field_experience,
section#vacature .department {
  display: flex;
  gap: 30px;
}
section#vacature .cv_upload {
  height: auto;
  border: none;
  margin: 0;
  padding: 0;
  border-radius: unset;
  box-shadow: none;
  outline: none;
  resize: none;
  cursor: pointer;
}
section#vacature .uploadField {
  margin: 0;
}

section#vacature .vacature-image__container {
  height: 100%;
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border: 1px solid var(--grey-20);
  border-radius: 5px;
  margin: 25px;
}
section#vacature .vacature-image {
  height: 100%;
  width: unset;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section#vacature .jobForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1000px;
}
section#vacature {
  width: 100%;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  border-radius: 5px;
  display: flex;
}
section#vacature .button {
  width: 300px;
}
section#vacature .buttonWrapper {
  margin: 10px auto;
}
form input {
  margin: unset;
}
/* section#vacature input,
section#vacature textarea,
section#vacature label,
section#vacature select {
  font-size: 12px;
  max-width: unset !important;
  box-shadow: 1px 1px 3px rgb(0 0 0 / 10%);
  border-radius: 5px;
  border-color: var(--grey-20) !important;
  border: solid 1px;
  height: 40px;
  min-width: 150px;
  padding-left: 10px;
  margin: 5px 0px;
  max-width: 90%;
} */
section#vacature textarea {
  height: 70px;
}
section#vacature .jobFormWrap {
  padding-top: 0.8em;
}
section#vacature .jobFormWrap.radio .radio {
  height: unset;
  min-width: unset;
  margin-right: 1em;
}
section#vacature .jobFormWrap.checks .check {
  height: unset;
  min-width: unset;
  margin-right: 1em;
}
section#vacature .jobFormWrap {
  margin: 0;
  display: grid;
  width: 100%;
}
section#vacature .jobFormWrap .inputFieldWrapper {
  max-width: unset;
}
section#vacature .jobFormWrap.four {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
section#vacature .jobFormWrap.three {
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 10px;
}
section#vacature .jobFormWrap.radio div {
  display: flex;
  align-items: center;
}
section#vacature .jobFormWrap.two {
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
section#vacature .jobAppWrapper {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  max-width: 960px;
}
section#vacature h2 {
  color: var(--grey-50);
  text-align: center;
  font-weight: 200;
}
section#vacature .jobFillContent {
  position: relative;
  z-index: 3;
}
section#vacature .jobAppOverlay {
  opacity: 0.75;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
section#vacature .jobAppBg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}
section#vacature .jobFill .jobFillContent span.jobFillText {
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -moz-border-bottom-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  border-bottom-right-radius: 5px;
  -moz-border-top-right-radius: 5px;
  -webkit-border-top-right-radius: 5px;
  border-top-right-radius: 5px;
  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;
  line-height: 45px;
  color: var(--grey-50);
  font-size: 20px;
}
section#vacature select {
  border-radius: 3px;
  border: solid 1px var(--grey-20);
  padding: 10px 15px;
  margin: 5px;
  transition: 0.25s;
}
section#vacature select {
  width: calc(100% - 10px);
}
section#vacature #ja_degree,
section#vacature #ja_function {
  width: calc(100% - 10px);
}
section#vacature #ja_message {
  width: 100%;
  box-shadow: none;
  border: solid 1px var(--grey-20);
  resize: vertical;
  border-radius: 5px;
  padding: 10px 15px;
  margin: 5px 0;
}
section#vacature #ja_send {
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 15px;
  transition: 0.25s;
  padding: 7px 15px;
  margin-top: 1em;
  cursor: pointer;
  width: 200px;
  background-color: var(--main);
  border-radius: 5px;
  border: 1px solid var(--main);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
  z-index: 0;
  transition: 0.3s;
}
section#vacature #ja_send:hover::after {
  opacity: 0.2;
}
section#vacature #ja_send::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -5px;
  left: -5px;
  z-index: -1;
  border: 5px solid var(--main);
  background: var(--main);
  border-radius: 5px;
  opacity: 0;
  transition: 0.1s;
}

section#vacature #ja_file {
  display: none;
}

@media (max-width: 1200px) {
  section#vacature #jobApplication {
    flex-direction: column;
  }
  section#vacature .vacature-image__container {
    width: 80vw;
    height: 300px;
    align-self: center;
    border: none;
  }
  section#vacature .vacature-image {
    border: 1px solid var(--grey-20);
    border-radius: 5px;
  }
  section#vacature .vacature-content {
    width: 100%;
  }
}
@media (max-width: 750px) {
  section#vacature .jobForm {
    width: 100% !important;
  }
  section#vacature .jobFormWrap {
    display: flex;
    grid-gap: 0 !important;
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  section#vacature .jobFormWrap .inputFieldWrapper {
    width: 100%;
    max-width: 300px;
    margin: 5px 0;
  }
  section#vacature .jobFormWrap .birthdate {
    width: 100%;
    max-width: 300px;
    margin: 5px 0;
    height: 35px;
  }
  section#vacature .jobFormWrap.full {
    margin-top: 40px;
    align-items: flex-start;
    padding: 0 60px;
    max-width: 400px;
  }
  section#vacature .job_choice,
  section#vacature .department {
    flex-direction: column;
    gap: 0;
  }
  section#vacature .motivatie {
    width: 100%;
    height: 100%;
    padding: 20px 0;
    max-width: 450px;
  }
  section#vacature .jobFormWrap.send {
    max-width: 450px;
  }

  section#vacature #ja_send {
    margin-top: 50px;
  }
  .bottomFooter {
    font-size: 9px;
  }
}

@media (max-width: 1600px) {
  section#vacature .jobForm {
    width: 700px;
  }
}
@media (max-width: 1000px) {
  section#vacature {
    flex-direction: column;
  }
  /* section#vacature .vacature-image__container {
    width: 100vw;
    height: 100px;
  } */
  section#vacature .vacature-image {
    height: 100%;
    width: unset;
  }
}

/**** login ****/

section#login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(var(--sub-lg), var(--sub-lg)), url("https://images.unsplash.com/photo-1649086652020-075fb1626f86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80");
  background-size: cover;
}

section#login form.login-form {
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  width: 40vw;
  height: 40vh;
}
section#login form.login-form div {
  width: 60%;
}
section#login form.login-form div label {
  color: var(--main);
  font-weight: 700;
}

section#login form.login-form div input {
  margin: 5px 0 20px;
  height: 35px;
}
section#login form.login-form div input:hover {
  border: 1px solid var(--sub);
}
section#login form.login-form div input:focus-visible {
  border: 1px solid var(--main) !important;
}

section#login form.login-form #submit-login {
  background-color: var(--sub);
  border: 1px solid var(--sub);
  color: white;
  font-size: 1em;
  padding: 5px;
  max-height: unset;
  height: unset;
}
section#login form.login-form #submit-login:hover {
  background-color: var(--main);
}

/**** fotoalbum ****/
section#fotoalbum {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 8em 0 2em;
}
section#fotoalbum .video-container {
  height: 700px;
  width: 80vw;
  margin-bottom: 1em;
  border-radius: 5px;
  overflow: hidden;
}
section#fotoalbum .video {
  height: 100%;
}
section#fotoalbum .foto-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 80vw;
  grid-gap: 10px;

  /* new code by "B" */
  grid-template-columns: repeat(auto-fit, minmax(250px, 3fr));
}
section#fotoalbum .video-react .video-react-big-play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
section#fotoalbum .image-container {
  border: 1px solid var(--grey);
  height: 250px;
  width: auto;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
}
section#fotoalbum .image {
  height: 100%;
  position: absolute;
}
section#fotoalbum .image-container .overlay {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-tp);
  color: white;
  opacity: 0;
  transition: 0.3s;
}
section#fotoalbum .image-container .overlay:hover {
  opacity: 1;
  transition: 0.3s;
}

.ReactModalPortal {
  z-index: 9;
}
.ReactModalPortal .ReactModal__Content.fotoalbum {
  width: 80vw;
  height: 80vh;
  /* min-width: 1250px;
  min-height: 600px; */
  border-radius: 5px;
  overflow: hidden;
  z-index: 1;
  justify-content: center;
}
.ReactModalPortal .ReactModal__Content.fotoalbum .slick-slide div {
  width: unset !important;
  height: 100%;
}
.ReactModalPortal .ReactModal__Content.fotoalbum .slick-slider {
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 1920px;
  border: 5px solid var(--grey-5);
}
.ReactModalPortal .ReactModal__Content.fotoalbum .slick-track {
  display: flex !important;
  height: 100%;
}
.ReactModalPortal .ReactModal__Content.fotoalbum .slick-slider .slick-slide {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-100);
}
.ReactModalPortal .ReactModal__Content.fotoalbum .slick-slider .slick-prev {
  left: 10px;
  z-index: 1;
}
.ReactModalPortal .ReactModal__Content.fotoalbum .slick-slider .slick-next {
  right: 10px;
  z-index: 1;
}
.ReactModalPortal .ReactModal__Content.fotoalbum .slick-slider .slick-dots {
  bottom: 10px;
  z-index: 1;
}
.ReactModalPortal .ReactModal__Content.fotoalbum .slick-slider .slick-list {
  height: 100%;
  max-width: 1920px;
}
/* .ReactModalPortal .ReactModal__Content.fotoalbum .image-slider-item {
  height: 70vh;
}
.ReactModalPortal .ReactModal__Content.fotoalbum .image-slider-item img {
  height: 100%;
} */

.ReactModalPortal .ReactModal__Content.fotoalbum .image-slider-item {
  height: 70vh;
  position: relative;
  min-height: 80vh;
  width: unset !important;
}
.ReactModalPortal .ReactModal__Content.fotoalbum .image-slider-item img {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%; */

  /* added code by "B" */
  height: 100%;
}

/***********************************************/
/****************** Dropdown *******************/
/***********************************************/

.dropdown {
  float: right;
  width: 110px;
  height: 35px;
  font-size: 14px;
}
.dropdown .dropbtn {
  border: none;
  outline: none;
  font-size: 0.9em;
  color: white;
  padding: 9px 50px 9px 10px;
  background-color: inherit;
  width: 100%;
  text-align: left;
}
.dropdown .dropdown-content {
  position: absolute;
  background-color: var(--grey-100);
  width: 110px;
  z-index: 1;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.25s;
}
.dropdown .dropdown-content span {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  font-size: 0.9em;
  height: 35px;
  line-height: 7px;
  width: 100%;
  cursor: pointer;
}
.dropdown .dropdown-content span:hover {
  background-color: var(--grey-90);
}
.dropdown:hover .dropbtn {
  background-color: var(--grey-90);
}
.dropdown:hover .dropdown-content {
  transform: scaleY(1);
}

/***********************************************/
/***************** Navigation ******************/
/***********************************************/
nav {
  position: fixed;
  top: 0;
  z-index: 9;
  color: var(--grey-90);
  font-size: 14px;
  width: 100%;
}
nav .topNav {
  background-color: var(--grey-100);
  height: 35px;
}
nav .mainNav {
  width: 100%;
  background: white;
  border-bottom: 1px solid var(--grey-30);
  box-shadow: 0 0 25px rgb(0 0 0 / 20%);
}
nav .PageNav {
  display: flex;
  align-items: center;
}
nav .PageNav a {
  height: unset !important;
}
nav .mainNav .navLogo {
  height: 50px;
  margin-left: 0.7em;
  width: 160px;
}
nav .mainNav .navLogo img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
nav .mainNav a {
  color: white;
}
nav .mainNav.tNav {
  background: transparent;
}
nav .mainNav.tNav a {
  color: var(--grey-90);
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
nav .mainNav .navItems {
  margin: 0;
  padding: 0;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav .mainNav .navItems .dropdown {
  display: none;
  width: 100%;
  height: 50px;
}
nav .mainNav .navItems .dropdown .dropbtn {
  color: var(--grey-90);
  font-size: 15px;
  padding: 13px;
  height: 50px;
}
nav .mainNav .navItems .dropdown:hover .dropbtn {
  background-color: var(--grey-10);
}
nav .mainNav .navItems .dropdown .dropdown-content {
  font-size: 14px;
  width: 100%;
  background-color: transparent;
}
nav .mainNav .navItems .dropdown:hover .dropdown-content a {
  color: var(--grey-90);
  height: 50px;
  padding: 13px;
  font-size: 15px;
  line-height: inherit;
}
nav .mainNav .navItems .dropdown .dropdown-content a:hover {
  background-color: var(--grey-10);
}
nav .mainNav li {
  display: flex;
  flex-direction: column;
  height: 56px;
  position: relative;
  justify-content: center;
  transition: 0.25s;
}
nav .mainNav li.highlighted {
  order: 9;
}
nav .mainNav li.highlighted a {
  background-color: var(--main);
  border-radius: 5px;
  color: white !important;
}

/* nav .mainNav li.active a {
  text-decoration: underline red 1px;
  text-underline-offset: 5px;
} */
nav .mainNav li a {
  display: block;
  padding: 13px;
  text-decoration: none;
}
nav .mobileLogo {
  display: none;
}
nav .mobileNav {
  display: none;
  padding: 8px 10px;
  float: right;
  cursor: pointer;
}
nav .mobileNav .navIcon {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
}
nav .mainNav li::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: #fcb721;
  background: var(--main);
  transition: 0.15s;
  -webkit-transform: scaleX(0);
  bottom: 0;
  transform: scaleX(0);
  -webkit-transform-origin: center;
  transform-origin: center;
  position: absolute;
}
nav .mainNav.color li:hover a {
  color: var(--main);
}
nav .mainNav.underline li:hover::after,
nav .mainNav li.active::after {
  transform: scaleX(1);
}
nav .mainNav.grow li:hover {
  transform: scale(1.05);
}
nav .mainNav.float li:hover {
  transform: translateY(-3px);
}
nav .mainNav.background li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

nav .langBlock2 {
  display: flex;
  font-weight: 900;
  justify-content: space-between;
  margin-right: 50px;
  width: 50px;
  gap: 5px;
}
nav .langBlock2 a.active {
  color: var(--main);
  pointer-events: none;
}
nav .langBlock2.oh_colors a.active {
  color: var(--oh_color);
}
nav .langBlock2 a:hover {
  color: var(--main);
  cursor: pointer;
  text-decoration: underline;
}

/***********************************************/
/******************* Header ********************/
/***********************************************/
header {
  background-size: cover;
  background-position: center;
  position: relative;
}
header .logo {
  max-width: 450px;
  width: 100%;
  margin: 0 auto 25px auto;
}
header .signature {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 50px;
}
header .background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
header .background .backgroundOverlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
header .background video,
header .background iframe {
  background-color: black;
}
header .backgroundOverlay {
  background-color: rgba(0, 0, 0, 0.1);
}

header section.openinghours {
  font-family: Roboto;
  border-radius: 4px;
  box-shadow: 0 0 10px black;
  padding: 0 50px 50px !important;
  overflow: hidden;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  height: 100%;
  float: right;
}
header section.openinghours.inactive {
  display: none;
}
header section.openinghours.left {
  float: left;
}
header section.openinghours .header h2 {
  text-align: center;
  color: white !important;
  margin: 0 auto;
  padding: 50px 0 !important;
}
header section.openinghours .opening-hours-table tr td {
  padding: 5px;
  font-family: var(--secundaryFont);
  font-size: 22px;
  /* text-transform: capitalize; */
}
header section.openinghours .opening-hours-table tr.active p,
header section.openinghours .opening-hours-table tr.holliday p {
  font-weight: 500 !important;
}
header section.openinghours .opening-hours-table tr.active {
  color: var(--main);
}
header section.openinghours .opening-hours-table tr.holliday {
  color: #fcb721;
}
header section.openinghours.oh_colors .opening-hours-table tr.active {
  color: var(--oh_color);
}
/***********************************************/
/******************* Footer ********************/
/***********************************************/
footer {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
/* footer div {
    padding: 25px;
} */
footer h2 {
  font-weight: 200;
  padding: 25px;
  margin: 0;
}
footer a {
  text-decoration: none;
}
footer ul {
  margin: 0;
  padding: 0;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding-bottom: 25px;
}
footer li {
  display: block;
}
footer li a {
  display: flex;
  align-items: center;
}

footer .icon {
  width: 30px;
  width: 30px;
  /* margin-right: 10px; */
  vertical-align: middle;
}
footer .topFooter,
footer .bottomFooter {
  width: 100%;
  box-sizing: border-box;
}
footer .topFooter {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  padding: 25px;
}
footer .footerPrivacy,
footer .footerEnterprise,
footer .footerConnect,
footer .footerLogin {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* footer .footerConnect {
  flex-grow: 4;
  text-align: center;
} */
footer .footerConnect h2 {
  text-align: center;
}
footer .footerConnect li {
  display: inline-block;
}
footer .footerConnect textarea {
  max-width: 250px;
  min-height: 75px;
  margin-bottom: 10px;
  font-size: 12px;
}
footer .footerConnect input[type="submit"] {
  margin: 0 auto;
  max-width: 250px;
  margin-bottom: 20px;
}
footer .footerLogin ul {
  padding: 10px;
  background-color: var(--main);
  border-radius: 5px;
  font-weight: 900;
  cursor: pointer;
}
footer .footerLogin ul a {
  color: white;
}
footer .footerLogin ul a:hover {
  color: white;
}
footer .siteMapFooter {
  text-align: center;
  padding: 25px 0;
  margin-top: 50px;
}
footer .siteMapFooter li {
  display: inline-block;
  padding: 0 25px;
  text-transform: uppercase;
}
footer .bottomFooter {
  text-align: center;
}

/* Theme: Light */

footer.light {
  background-color: var(--grey-5);
  color: var(--grey-50);
}
footer.light h2 {
  color: var(--grey-50);
}
footer.light a {
  color: var(--grey-90);
}
footer.light a:hover {
  color: var(--main);
}
footer.light .siteMapFooter {
  border-top: 1px solid var(--grey-20);
}
footer.light .bottomFooter {
  background-color: var(--grey-10);
}
footer.light input[type="submit"] {
  background-color: transparent;
  border: 1px solid var(--grey-50);
  color: var(--grey-50);
}

/* Theme: Dark */

footer.dark {
  background-color: var(--grey-90);
  color: white;
}
footer.dark h2 {
  color: white !important;
}
footer.dark a {
  color: var(--grey-20);
}
footer.dark a:hover {
  color: var(--main);
}
footer.dark .siteMapFooter {
  border-top: 1px solid var(--grey-80);
}
footer.dark .bottomFooter {
  align-items: center;
  background-color: var(--grey-100);
  display: flex;
  height: 60px;
  justify-content: center;
}
footer.dark input[type="submit"] {
  background-color: transparent;
  border: 1px solid var(--grey-50);
  color: var(--grey-50);
}

/* Theme: Main colour */

footer.main {
  background-color: var(--main);
  color: rgba(0, 0, 0, 0.75);
}
footer.main h2 {
  color: rgba(0, 0, 0, 0.75);
}
footer.main a {
  color: rgba(0, 0, 0, 0.5);
}
footer.main a:hover {
  color: rgba(0, 0, 0, 0.75);
}
footer.main .siteMapFooter {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
footer.main .bottomFooter {
  background-color: var(--sub);
}
footer.dark .bottomFooter a {
  text-decoration: underline;
}
footer.main input[type="submit"] {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

/* Theme: Sub colour */

footer.sub {
  background-color: var(--sub);
  color: rgba(0, 0, 0, 0.75);
}
footer.sub h2 {
  color: rgba(0, 0, 0, 0.75);
}
footer.sub a {
  color: rgba(0, 0, 0, 0.5);
}
footer.sub a:hover {
  color: rgba(0, 0, 0, 0.75);
}
footer.sub .siteMapFooter {
  border-top: 1px solid rgba(0, 0, 0, 0.25);
}
footer.sub .bottomFooter {
  background-color: var(--main);
}
footer.sub input[type="submit"] {
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.5);
}

/* @media (max-width: 1200px) {
  section#aboutUs.temp-4 .aboutContent {
    padding: 2em;
  } */
/* } */

/* @media only screen and (max-width: 1403px) { */
@media only screen and (max-width: 1350px) {
  nav {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    height: 50px;
  }
  nav .topNav {
    display: none;
  }
  nav .mobileNav,
  nav .mobileLogo {
    display: block;
    /* width: 120px; */
  }
  nav .mainNav.tNav {
    background: transparent;
    display: flex;
    height: 57px;
    justify-content: space-between;
    align-items: center;
  }
  nav .mainNav .navItems {
    position: fixed;
    width: 250px;
    background-color: white;
    height: 100%;
    text-align: left !important;
    right: -250px;
    z-index: 10;
    top: 50px;
    transition: 0.5s;
  }
  nav .mainNav .navItems .dropdown {
    display: block;
  }
  nav .mainNav li {
    display: block;
  }
  nav .mobileNav:hover + .navItems,
  nav .mainNav .navItems:hover {
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 47px);
  }
  nav .mainNav .navItems .navLogo {
    display: none;
  }
  nav .PageNav {
    flex-direction: column;
  }

  nav .langBlock2 {
    margin-right: 0;
    margin-bottom: 20px;
  }

  nav .mainNav li:hover::after {
    transform: scaleX(0);
  }
  nav .mainNav li:hover {
    background-color: var(--grey-10);
  }
  header {
    min-height: 350px;
  }
  section#aboutUs.temp-1 .aboutContent {
    max-width: 100%;
  }
  section#aboutUs.temp-1 .aboutImg {
    filter: blur(5px);
    transform: scale(1.1);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgb(0, 0, 2)), url("https://o-sn.be/onepagedemo/about.jpg") !important;
  }

  section.onzeTroeven {
    justify-content: flex-start !important;
    width: 100%;
    overflow: hidden;
    height: auto !important;
    padding: 50px 0 !important;
  }
  section.onzeTroeven .troef_section {
    width: 100%;
    max-width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  footer h2 {
    text-align: center;
  }
  footer .topFooter {
    display: block;
  }
  footer .footerPrivacy,
  footer .footerEnterprise,
  footer .footerConnect,
  footer .footerLogin {
    width: 100%;
    /* margin-bottom: 50px; */
  }
}

#testMode .deleteButton {
  height: max-content;
  align-self: center;
  margin-left: 5px;
}

p:last-child {
  margin-bottom: 0;
}
html {
  scroll-behavior: smooth;
}
/* input,
textarea {
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  resize: vertical;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
input[type="submit"] {
  width: 100%;
  max-width: 150px;
  display: block;
  box-shadow: none;
  text-transform: uppercase;
} */

/***********************************************/
/***************** About ******************/
/***********************************************/
.wrapper#Aboutus {
  background-color: var(--grey);
  padding: 50px 0;
}
section#about {
  height: 50vh;
  background-color: transparent;
  padding: 0 4em !important;
}
section#about .content {
  display: flex;
  height: 100%;
  gap: 40px;
}
section#about .content figure {
  position: relative;
  min-width: 33%;
  overflow: hidden;
}
section#about .content figure img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
section#about .content .text {
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 60px;
}
@media (max-width: 1200px) {
  section#about {
    height: auto;
  }
  section#about .content {
    flex-direction: column;
    margin-top: 20px;
  }
  section#about .content figure {
    height: 300px;
    border-radius: 5px;
  }
  section#about .content figure img {
    width: 105%;
  }
  section#about .content .text {
    width: 100%;
  }
}
@media (max-width: 500px) {
  section#about .content figure {
    height: 200px;
  }
  section#about .content figure img {
    width: unset;
    height: 100%;
  }
  section#about .content .text {
    text-align: justify;
  }
}

/***********************************************/
/***************** Souffriau ******************/
/***********************************************/
.wrapper#Souffriau {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./library/images/souffriau-background.jpg");
  background-size: cover;
  box-sizing: border-box;
  height: 70vh;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 100px;
  width: 100%;
}
.wrapper#Souffriau section.souffriau {
  background-color: transparent;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper#Souffriau section.souffriau .logo {
  background: url("./library/images/souffriau-logo.png") no-repeat center;
  background-size: contain;
  height: 40%;
  width: 100%;
  opacity: 0;
}
.wrapper#Souffriau section.souffriau h1 {
  color: white;
  font-size: 35px;
  font-weight: bold;
  margin: 0 auto 20px;
  max-width: 100%;
  text-align: center;
  padding-top: 50px;
}
.wrapper#Souffriau section.souffriau p {
  color: white;
  font-size: 20px;
  font-weight: bold;
  max-width: 100%;
  text-align: center;
  margin-bottom: 40px;
}
.wrapper#Souffriau section.souffriau a {
  color: white;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.wrapper#Souffriau section.souffriau a:hover .icon {
  transform: translateY(10px);
}
.wrapper#Souffriau section.souffriau a .icon {
  width: 100%;
  height: 100%;
  transition: 0.2s;
}
/***********************************************/
/***************** banner ******************/
/***********************************************/
.wrapper#Banner {
  height: 70vh;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./library/images/banner/banner-background.jpg");
  background-size: cover;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 180px;
  padding-bottom: 20px;
  width: 100%;
}
.wrapper#Banner section#banner {
  height: 100%;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wrapper#Banner section#banner figure {
  height: 25%;
  position: relative;
}
.wrapper#Banner section#banner figure img {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper#Banner section#banner h3 {
  color: white;
  margin-top: 70px;
  font-family: "Roboto", sans-serif;
}
@media (max-width: 1000px) {
  .wrapper#Banner section#banner figure {
    width: 80%;
  }
  .wrapper#Banner section#banner figure img {
    width: 100%;
    height: unset;
  }
}

/***********************************************/
/***************** TeamImage ******************/
/***********************************************/
.wrapper#Team {
  height: max-content;
  background-size: cover;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 180px;
  padding-bottom: 20px;
  width: 100%;
}
.wrapper#Team #teamImage figure {
  margin-top: 20px;
  height: max-content;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid var(--grey-10);
  line-height: 0;
}
.wrapper#Team #teamImage figure img {
  object-fit: cover;
  width: 100%;
}

/***********************************************/
/***************** local products ******************/
/***********************************************/
section#local_products {
  padding: 0 4em !important;
}
section#local_products h2 {
  margin-bottom: 40px !important;
}
section#local_products p {
  text-align: center;
}

section#local_products .slick-slide {
  position: relative;
  height: 190px;
}
section#local_products .slick-slide div {
  width: 100%;
  height: 100%;
}
section#local_products .slick-slide a {
  display: block;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
section#local_products .slick-slide:hover img {
  transform: scale(1.1);
}
section#local_products .slick-slide a img {
  width: 100%;
  height: auto;
  transition: 0.3s;
}
section#local_products .slick-list {
  z-index: 1;
}
section#local_products .slick-next {
  /* right: 0; */
  z-index: 2;
}
section#local_products .slick-prev {
  /* left: 0; */
  z-index: 2;
}
section#local_products .slick-dots {
  position: unset;
}
section#local_products .slick-prev:before,
section#local_products .slick-next:before {
  color: var(--grey-100);
  position: relative;
}

/***********************************************/
/***************** tarteAMoi ******************/
/***********************************************/
section#vacatureSuperplanner {
  height: auto;
  background-color: var(--grey);
  padding: 50px 0 !important;
}
section#vacatureSuperplanner .content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  gap: 20px;
}
section#vacatureSuperplanner .content p {
  margin: 5px 0;
  width: 80%;
  text-align: center;
  font-size: 18px;
}
section#vacatureSuperplanner .content p a {
  color: var(--main);
  font-weight: 900;
  text-decoration: underline;
  padding: 5px 2px;
  transition: 0.2s;
}
section#vacatureSuperplanner .content p a:hover {
  background: var(--main);
  color: white;
  border-radius: 5px;
}
section#vacatureSuperplanner .content .figure {
  height: 300px;
  position: relative;
  width: auto;
  border-radius: 5px;
  overflow: hidden;
  margin-top: auto;
  transition: 0.2s;
}
section#vacatureSuperplanner .content .figure:hover {
  transform: scale(1.1);
}
section#vacatureSuperplanner .content .figure img {
  /* position: absolute; */
  height: 100%;
}

@media (max-width: 640px) {
  section#vacatureSuperplanner .content .figure {
    height: auto;
    /* min-height: 200px; */
    width: 50%;
  }
  section#vacatureSuperplanner .content .figure img {
    width: 100%;
    height: unset;
  }
}
/***********************************************/
/***************** tarteAMoi ******************/
/***********************************************/
section#tarteAMoi {
  height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url("./library/images/tarte-background.png") no-repeat top;
}
section#tarteAMoi .tarte {
  height: 100%;
  padding-top: 60px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section#tarteAMoi .logo {
  background: url("./library/images/tarte-logo.svg") no-repeat top;
  background-size: contain;
  height: 30%;
}
section#tarteAMoi a {
  color: white;
  display: block;
  margin: 0 auto 20px;
  max-width: 700px;
  padding-top: 25px;
  text-decoration: underline;
}
section#tarteAMoi a p {
  color: white;
  font-size: 22px;
  font-weight: bold;
  margin: 0 auto 20px;
  max-width: 700px;
  text-align: center;
}
/***********************************************/
/***************** panosbanner ******************/
/***********************************************/

section.onzeTroeven {
  height: calc(100vh - 57px);
  margin-top: 57px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-5);
  gap: 60px;
}

section.onzeTroeven .troef_section {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
section.onzeTroeven .troef_section.spacer {
  width: 70%;
  display: flex;
  align-items: center;
  position: relative;
}
section.onzeTroeven .troef_section.spacer .icon {
  width: 40px;
  height: 40px;
}
section.onzeTroeven .troef_section.spacer::before {
  position: absolute;
  content: "";
  top: 60%;
  background: black;
  width: 50%;
  height: 2px;
  left: -30px;
  transform: translate(0, -50%);
}
section.onzeTroeven .troef_section.spacer::after {
  position: absolute;
  content: "";
  top: 60%;
  background: black;
  width: 50%;
  height: 2px;
  right: -30px;
  transform: translate(0, -50%);
}
section.onzeTroeven h2 {
  color: #000000 !important;
  font-family: "Roboto Slab", Sans-serif;
  font-size: 40px;
  font-weight: 900 !important;
  margin-bottom: 20px !important;
}
section.onzeTroeven .troef_container {
  display: flex;
  flex-direction: column;
  color: black;
  gap: 5px;
}
section.onzeTroeven .troef_container span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
  font-weight: 400;
}
section.onzeTroeven .troef_section p {
  font-size: 15px;
  color: black;
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 20px;
  max-width: 1000px;
  text-align: center;
}
section.onzeTroeven .troef_container .icon {
  min-width: 25px;
  min-height: 25px;
}
/***********************************************/
/***************** panosbanner ******************/
/***********************************************/
.wrapper#PanosQ8 {
  height: 50vh;
  /* padding-left: 15px;
  padding-right: 15px;
  padding-top: 180px;
  padding-bottom: 20px; */
  width: 100%;
  overflow: hidden;
  position: relative;
}
.wrapper#PanosQ8 section#panosbanner {
  height: 100%;
  background-color: unset;
  display: flex;
  padding: 0 !important;
}
.wrapper#PanosQ8 section#panosbanner .q8,
.wrapper#PanosQ8 section#panosbanner .panos {
  width: 50%;
}
.wrapper#PanosQ8 section#panosbanner .q8 {
  background-color: rgba(254, 209, 8, 0.75);
  display: flex;
}
.wrapper#PanosQ8 section#panosbanner figure {
  position: relative;
}
.wrapper#PanosQ8 section#panosbanner figure img {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.wrapper#PanosQ8 section#panosbanner .q8 .smile {
  height: 30%;
  left: 150px;
  top: 100px;
  position: absolute;
}
.wrapper#PanosQ8 section#panosbanner .q8 .app {
  height: 100%;
  left: -220px;
  bottom: -190px;
  position: relative;
  width: 500px;
}
.wrapper#PanosQ8 section#panosbanner .q8 .app img {
  width: 100%;
  height: auto;
}

.wrapper#PanosQ8 section#panosbanner .q8 .q8-child {
  width: 40%;
}
.wrapper#PanosQ8 section#panosbanner .q8 .q8-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  width: 60%;
}
.wrapper#PanosQ8 section#panosbanner .q8 .q8-content figure {
  width: 50%;
  min-height: 50px;
}
.wrapper#PanosQ8 section#panosbanner .q8 .q8-content h3 {
  color: #20419a;
  font-size: 2rem;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
}
.wrapper#PanosQ8 section#panosbanner .mobile_download_buttons {
  display: flex;
  width: 300px;
  gap: 20px;
}
.wrapper#PanosQ8 section#panosbanner .mobile_download_buttons a {
  width: 50%;
}
.wrapper#PanosQ8 section#panosbanner .mobile_download_buttons a img {
  width: 100%;
}

.wrapper#PanosQ8 section#panosbanner .panos {
  background-color: rgba(244, 210, 5, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper#PanosQ8 section#panosbanner .panos .panos-child {
  width: 80%;
  height: 80%;
  padding: 40px;
  border: 4px solid white;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
}
.wrapper#PanosQ8 section#panosbanner .panos .panos-child figure {
  min-height: 120px;
  margin-top: -70px;
}
.wrapper#PanosQ8 section#panosbanner .panos .panos-child h3 {
  margin: 0 auto;
  font-family: "Supernett cn";
  font-size: 50px;
  line-height: 1em;
  text-decoration: none;
  color: white;
  font-weight: 100;
  text-align: center;
  margin-top: auto;
}

@media (max-width: 1130px) {
  .wrapper#PanosQ8 {
    height: unset;
  }
  .wrapper#PanosQ8 section#panosbanner {
    flex-direction: column;
  }
  .wrapper#PanosQ8 section#panosbanner .q8,
  .wrapper#PanosQ8 section#panosbanner .panos {
    width: 100%;
    overflow: hidden;
    height: 250px;
  }
  .wrapper#PanosQ8 section#panosbanner .q8 .app {
    left: -180px;
    bottom: -150px;
  }
  .wrapper#PanosQ8 section#panosbanner .q8 .smile {
    left: 270px;
    top: 60px;
  }
  .wrapper#PanosQ8 section#panosbanner .q8 .q8-content {
    gap: 0;
    padding: 60px 60px 0;
  }
  .wrapper#PanosQ8 section#panosbanner .q8 .q8-content figure {
    min-height: 30px;
  }
  .wrapper#PanosQ8 section#panosbanner .panos .panos-child {
    padding: 20px;
  }
  .wrapper#PanosQ8 section#panosbanner .panos .panos-child figure {
    margin-top: 0;
    width: 100%;
    min-height: 80px;
  }

  .wrapper#PanosQ8 section#panosbanner .panos .panos-child h3 {
    font-size: 2.5em;
    line-height: 1;
  }
}

@media (max-width: 640px) {
  .wrapper#PanosQ8 section#panosbanner .q8 .app {
    left: -100px;
    bottom: -110px;
    width: 290px;
  }
  .wrapper#PanosQ8 section#panosbanner .q8 .smile {
    left: 100px;
    height: 20%;
  }
  .wrapper#PanosQ8 section#panosbanner .q8 .q8-content {
    width: 70%;
    gap: 10px;
  }
  .wrapper#PanosQ8 section#panosbanner .q8 .smiles {
    width: 30%;
  }
  .wrapper#PanosQ8 section#panosbanner .q8 .q8-content h3 {
    font-size: 1.2em;
    line-height: 1;
  }
  .wrapper#PanosQ8 section#panosbanner .mobile_download_buttons {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 170px;
    align-items: center;
    gap: unset;
  }
  .wrapper#PanosQ8 section#panosbanner .panos .panos-child h3 {
    font-size: 1.4em;
    line-height: 1;
  }
}

/***********************************************/
/***************** HappyGateau ******************/
/***********************************************/

.wrapper#HappyGateau {
  box-sizing: border-box;
  background: url("./library/images/background-happy-gateau.jpg") no-repeat top;
  height: 80vh;
  padding: 0 15px;
  width: 100%;
}
.wrapper#HappyGateau section#happygateau {
  height: 100%;
  background-color: transparent;
}
.wrapper#HappyGateau .logo {
  background: url("./library/images/logo-happy-gateau.png") no-repeat top;
  background-size: contain;
  height: 60%;
}
.wrapper#HappyGateau a {
  color: #d10873;
  display: block;
  margin: 0 auto 20px;
  max-width: 250px;
  padding-top: 25px;
  text-decoration: underline;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.wrapper#HappyGateau a p {
  color: #d10873;
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto 20px;
  max-width: 250px;
  text-align: center;
}
.wrapper#HappyGateau a .icon {
  width: 40px;
  height: 40px;
}

/***********************************************/
/***************** Toogoodtogo ******************/
/***********************************************/
.wrapper#TooGoodToGo {
  height: 70vh;
  background: #bcece5;
  background-size: cover;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 180px;
  padding-bottom: 20px;
  width: 100%;
}
.wrapper#TooGoodToGo section#toogoodtogo {
  height: 100%;
  background-color: unset;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.wrapper#TooGoodToGo section#toogoodtogo figure {
  height: 35%;
  position: relative;
  min-height: 100px;
}
.wrapper#TooGoodToGo section#toogoodtogo figure img {
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapper#TooGoodToGo section#toogoodtogo h3 {
  color: #114d4d;
  font-size: 40px;
  font-weight: 900 !important;
  text-transform: uppercase;
  text-align: center;
  font-family: Poppins, Source Sans Pro, Helvetica Neue, "Sans Serif" !important;
}
.wrapper#TooGoodToGo section#toogoodtogo .mobile_download_buttons {
  display: flex;
  width: 300px;
  gap: 20px;
}
.wrapper#TooGoodToGo section#toogoodtogo .mobile_download_buttons a {
  width: 50%;
}
.wrapper#TooGoodToGo section#toogoodtogo .mobile_download_buttons a img {
  width: 100%;
}

/***********************************************/
/******************** mobile *******************/
/***********************************************/

section#socialNews.temp-1.mobile .sn_card {
  position: unset;
  background-color: white;
  box-shadow: 0 0 12px 0 rgb(71, 71, 71);
  cursor: unset;
}

section#socialNews.temp-1.mobile .sn_logo {
  display: none;
}

section#socialNews.temp-1.mobile .sn_image {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: unset;
}
section#socialNews.temp-1.mobile .sn_image img {
  transform: unset;
  filter: unset;
  opacity: unset;
  scale: unset;
}
section#socialNews.temp-1.mobile .sn_card:hover .sn_image img {
  transform: unset;
  filter: unset;
  opacity: unset;
  scale: unset;
}
section#socialNews.temp-1.mobile .sn_message {
  position: unset;
  transform: unset;
  scale: unset;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

section#socialNews.temp-1.mobile .sn_card:hover .sn_message {
  scale: unset;
}

section#socialNews.temp-1.mobile .sn_message strong {
  font-size: large;
  margin-bottom: 0.5em;
}

/***********************************************/
/**************** Privacy Policy ***************/
/***********************************************/
#privacypolicy,
#disclaimer {
  line-height: 30px;
  background-color: var(--grey-5);
  padding: 50px;
}
#privacypolicy #pp,
#disclaimer #dsc {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  max-width: 960px;
  margin: auto auto;
  box-sizing: border-box;
  padding: 50px;
  background-color: white;
  border: 1px solid var(--grey-30);
}
#privacypolicy a,
#disclaimer a {
  color: var(--main);
}
#privacypolicy #pp h1,
#privacypolicy #pp h2,
#privacypolicy #pp h3,
#disclaimer #dsc h1,
#disclaimer #dsc h2,
#disclaimer #dsc h3 {
  line-height: initial;
  margin-bottom: 50px;
}
#privacypolicy #pp h1,
#disclaimer #dsc h1 {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
}
#privacypolicy #pp h2,
#disclaimer #dsc h2 {
  font-size: 35px;
  margin-top: 25px;
}
#privacypolicy #pp h3,
#disclaimer #dsc h3 {
  margin-top: 25px;
  border-bottom: 1px solid var(--grey-50);
  padding-bottom: 25px;
  margin-bottom: 25px;
  text-align: left;
  color: var(--grey-50);
  font-size: 18px;
  text-transform: uppercase;
}
section#ourWork .item-image__container img {
  width: 100% !important;
  height: unset !important;
}

.pdf-page__container {
  display: flex;
  justify-content: center;
  background-color: var(--grey-10);
  padding: 3em 0;
}

.pdf-page__container .pdf-page__data {
  background-color: white;
  border-radius: 5px 0 0 5px;
  width: 50vw;
  height: 90vh;
  overflow: hidden;
}

.pdf-page__container .pdf-page__data .pdf-page__img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30%;
  overflow: hidden;
}

.pdf-page__container .pdf-page__data .pdf-page__img-container img {
  height: unset;
  width: 100%;
}

.pdf-page__container .pdf-page__content-container {
  padding: 4em;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.pdf-page__container .pdf-page__content h1 {
  font-size: x-large;
  margin-bottom: 1em;
}
.pdf-page__container .pdf-page__content p {
  font-size: large;
}
.pdf-page__container embed {
  width: 580px;
  height: 90vh;
}

@media (max-width: 1800px) {
  section#review.temp-2 .slick-slide {
    width: 545px;
  }
}
@media (max-width: 1150px) {
  section#review.temp-2 .slick-slide {
    width: 600px;
  }
}

@media (max-width: 1450px) {
  section#aboutUs.temp-3 .aboutImg {
    display: none;
  }
  section#ourWork .items {
    grid-template-columns: 1fr 1fr;
    padding: 2em 0;
  }
  section#ourWork .items .item .item-image__container {
    height: unset;
    max-height: 150px;
  }
  section {
    padding: unset !important;
  }
  section#aboutUs.temp-3 .aboutContent {
    margin: 6em;
    padding: 50px;
  }
  .pdf-page__container embed {
    width: 450px;
    height: 90vh;
  }
  section#fotoalbum .foto-list {
    grid-template-columns: repeat(3, auto);

    /* "B" */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  section#fotoalbum .video-container {
    height: 500px;
  }
  .ReactModalPortal .ReactModal__Content.fotoalbum .image-slider-item img {
    height: unset;
    width: 100%;
    top: 50%;
    position: relative;
    transform: translate(0, -50%);
  }
  .ReactModalPortal .ReactModal__Content.fotoalbum .image-slider-item {
    height: auto;
  }
  .ReactModalPortal .ReactModal__Content.fotoalbum .slick-slider {
    height: unset;
  }
  .ReactModalPortal .ReactModal__Content.fotoalbum {
    /* height: unset;
    width: unset; */
    margin: 0 3em !important;
  }
}

@media (max-width: 1400px) {
  section#review.temp-1 .card {
    width: unset;
  }
}
@media (max-width: 1150px) {
  section#review.temp-2 .container {
    width: 600px;
  }
  footer .footerPrivacy,
  footer .footerEnterprise,
  footer .footerConnect,
  footer .footerLogin {
    width: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 1000px) {
  section#review.temp-1 .card {
    width: 100%;
  }
  .ReactModalPortal .ReactModal__Content {
    flex-direction: column;
  }
  .ReactModalPortal .ReactModal__Content .modal-file {
    min-width: 100%;
    background-color: black;
  }
  .ReactModalPortal .ReactModal__Content .modal-file .slick-next {
    right: 10px;
  }
  .ReactModalPortal .ReactModal__Content .modal-file .slick-prev {
    left: 10px;
  }
  .ReactModalPortal .ReactModal__Content .modal-file .slick-dots {
    bottom: 80px;
  }
  .ReactModalPortal .ReactModal__Content .modal-file .slick-slider {
    width: 500px;
  }
  .ReactModalPortal .ReactModal__Content .modal-file .post-image__container {
    width: 500px;
    height: 500px;
  }
  section#social #feed-insta .feed-data .slick-dots {
    bottom: -25px;
  }
}

@media (max-width: 960px) {
  section#fotoalbum {
    margin-top: 3.5em;

    /* "B" */
    margin: 8em 0 2em;
  }
}

@media (max-width: 900px) {
  section#review.temp-1 .card-container {
    grid-template-columns: 1fr;
    padding: 0 1em;
  }
  section#review.temp-1 #box2 {
    height: unset;
  }

  header#home div.signature h1 {
    font-size: 1.5em;
    line-height: 2em;
  }
  section#team {
    padding: 1em 1em;
  }
  section#aboutUs.temp-4 h2 span {
    font-size: inherit !important;
  }
  section#aboutUs .aboutContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em !important;
  }

  section#aboutUs.temp-1 .aboutImg {
    display: none;
  }
  section#aboutUs.temp-2 .aboutContent {
    margin: 0.5em;
  }

  section#aboutUs h2 {
    font-size: 1.2em !important;
  }

  section#aboutUs p {
    font-size: 0.9em !important;
    width: 100%;
  }

  section#login form.login-form {
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    width: 90vw;
    height: 400px;
  }

  .pdf-page__container {
    flex-direction: column;
    width: 100%;
  }
  .pdf-page__container .pdf-page__data {
    width: 100vw;
  }
  .pdf-page__container embed {
    width: 100vw;
    height: 90vh;
  }
  section#fotoalbum .foto-list {
    grid-template-columns: repeat(2, auto);

    /* "B" */
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  section#fotoalbum .video-container {
    height: 300px;
  }
}

@media (max-width: 700px) {
  section#review.temp-2 .container {
    width: 400px;
  }
  section#review.temp-2 .container #box {
    height: unset;
  }
  section#jobApplication.temp-3 .card-image__container {
    height: unset;
  }

  section#ourWork .items {
    grid-template-columns: 1fr;
  }
  section#aboutUs.temp-3 .aboutContent {
    margin: 2em;
  }
  section#aboutUs h2 span {
    font-size: 1em !important;
  }
  section#review.temp-2 .slick-slide {
    width: 400px;
  }
  section#review.temp-2 .reviewslist .card {
    height: unset;
  }
  section#newsArticles.temp-2 .article {
    width: 200px;
    height: unset;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  section#newsArticles.temp-2 .article p,
  section#newsArticles.temp-2 .article #link {
    width: 80%;
    position: unset;
    margin-bottom: 1em;
  }
  section#newsArticles.temp-2 .article h3 {
    position: relative;
    width: 90%;
    top: -20px;
    left: 0;
  }
  section#newsArticles.temp-2 .article #link {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 15px;
  }
  section#newsArticles.temp-2 .article figure {
    height: unset;
  }

  section#newsArticles.temp-2 .article h3 span {
    font-size: 16px;
  }
  section#newsArticles.temp-3 .article {
    width: 200px;
    height: 300px;
  }
  section#newsArticles.temp-3 .article figure img {
    position: unset;
    transform: none;
  }
  section#newsArticles.temp-3 .article h3 {
    top: 200px;
    width: 200px;
    line-height: 1.3em;
  }
  section#newsArticles.temp-3 .article h3 span {
    font-size: 0.7em;
    padding: 2px 4px;
  }
  .container #onepageDemo section#newsArticles.temp-3 .article figure {
    height: 300px;
  }
  section#newsArticles.temp-3 .article #link {
    display: none;
  }
  section#newsArticles.temp-4 .container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  section#ourWork.temp-2 .item .item-content p {
    font-size: 16px;
    line-height: 1.5em;
  }
  section#social #feed-face .post {
    width: 350px;
  }
}
@media (max-width: 600px) {
  section h2 {
    font-size: 30px;
    padding: 0 0.5em;
  }
}
@media (max-width: 500px) {
  section#jobApplication .card-text .card__title {
    font-size: large;
    word-break: break-word;
    font-weight: 700;
  }
  section#jobApplication.temp-3 .card-text .card__text::before {
    content: "";
  }
  section#aboutUs .aboutContent {
    height: 100% !important;
  }
  section#e-shop .e-shop-button__container {
    flex-direction: column;
    align-items: center;
  }
  section#e-shop .e-shop-button {
    width: calc(75% - 2px);
    height: 50px;
  }
  section#social #feed-face .post {
    width: 250px;
  }
  section#social #feed-face .post-container .post-header img {
    width: 30px;
    height: 30px;
  }
  section#social #feed-face .post-container .post-header strong {
    font-size: 16px;
  }
  section#social #feed-face .post-container .post-header p {
    font-size: 12px;
  }
  section#fotoalbum .foto-list {
    grid-template-columns: repeat(1, auto);
  }
  section#fotoalbum .video-container {
    height: 200px;
  }
  section#fotoalbum .video-react {
    padding-top: 130px !important;
  }
  .ReactModalPortal .ReactModal__Content.fotoalbum {
    margin: 0 1em !important;
  }
}

@media (max-width: 450px) {
  section#jobApplication .card-text .card__title {
    font-size: large;
    word-break: break-word;
  }
  section#jobApplication.temp-1 .card-text .card__title {
    font-size: 16px !important;
    word-break: break-word;
  }
  section#team.temp-1 .teamMembers .teamColumn {
    flex-direction: column;
  }
  section#team.temp-1 .teamSocialMediaIcons {
    position: unset;
    margin-top: 1em;
  }
  section#team.temp-1 .teamImg img {
    width: 150px;
    margin-top: 1em;
    border-radius: 500px;
  }
  section#team.temp-1 .teamImg {
    width: 100%;
  }
  section#team.temp-1 .teamContent {
    width: 100%;
    padding: 20px 0;
  }
  section#team.temp-1 .teamContent h3 {
    text-align: center;
    padding: 0 0 5px;
  }
  section#team.temp-1 .teamContent h4 {
    text-align: center;
  }
  section#socialNews.temp-1.mobile .sn_image {
    height: unset;
  }

  section#review.temp-1 .card {
    flex-direction: column;
  }
  section#review.temp-1 .card #box1 {
    width: 100%;
    padding: 1em;
    border-bottom: 1px solid var(--main);
  }
  section#review.temp-1 .card #box2 {
    width: 100%;
    padding: 1em 2em;
    border-right: unset;
  }
  section#review.temp-1 .card #box2 p {
    padding-bottom: 0em;
  }
  section#review.temp-1 .card #box2 #stars {
    display: flex;
    justify-content: center;
    width: 100%;
    height: unset;
  }
  section#quote {
    padding: 0 0.5em 2em;
  }
  section#review.temp-2 .slick-slide {
    width: 250px;
  }
  section#review.temp-2 .reviewslist p {
    text-align: unset;
  }
  section#review.temp-2.light {
    width: 100%;
  }
  section#review.temp-2.dark {
    width: 100%;
    background-color: var(--grey-90) !important;
    align-items: center;
  }
  section#review.temp-2.dark .reviewslist {
    background-color: var(--grey-90) !important;
  }
  section#review.temp-2 .container {
    width: 250px !important;
    overflow: hidden;
  }
  section#review.temp-2 .container .reviewslist {
    width: 250px !important;
  }
  section#review.temp-2 .container .reviewslist {
    width: 250px !important;
  }

  section#newsArticles.temp-1 .article {
    width: 250px;
    height: unset;
  }
  section#newsArticles .article figure {
    height: unset;
  }
  section#newsArticles .article h3 {
    font-size: 15px !important;
  }
  section#newsArticles .article #link {
    margin-bottom: 1em;
  }
  .pdf-page__container .pdf-page__content h1 {
    font-size: 1em;
  }
  .pdf-page__container .pdf-page__content p {
    font-size: 1em;
  }
  .pdf-page__container div strong {
    font-size: 14px !important;
  }
  section#review.temp-1 .read-more {
    display: contents;
  }
  section#review.temp-1 .extra-text {
    display: none;
  }
  section#newsArticles.temp-4 .article {
    width: 250px;
  }
  section.temp-1 .card-image__container {
    height: unset;
  }
  footer .footerConnect ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

section#team.temp-2 .teamImg {
  padding: 50px 0;
  width: unset;
}
section#team.temp-2 .teamImg img {
  width: 150px;
}
section#team.temp-3 .teamImg img {
  width: 120px;
  height: 120px;
  margin: 15px;
  background-color: var(--grey-40);
}
section#team.temp-4 .teamImg img {
  height: unset;
}

section#team.temp-3 .teamSocialMediaIcons {
  background-color: unset;
}

section#team.temp-4 .teamSocialMediaIcons {
  background-color: unset;
}

section#team.temp-4 .teamImg {
  background-color: var(--grey-20);
}

section#jobApplication {
  padding: 0em;
}

@media (max-width: 370px) {
  section#fotoalbum .image-container {
    height: 200px;
  }
}

/* Video-Library / Recepten */
section#videoLibrary {
  margin-top: 110px;
  background-color: white !important;
  margin-bottom: 50px;
}

section#videoLibrary .slick-initialized .slick-slide {
  display: block;
  padding: 2px;
  border-radius: 5%;
  overflow: hidden;
}

section#videoLibrary .VideoLibrary .slick-arrow.slick-next {
  right: -30px;
}
section#videoLibrary .VideoLibrary .slick-arrow {
  border-radius: 100%;
  color: black;
  cursor: pointer;
  opacity: 0.5;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 9;
}
section#videoLibrary .VideoLibrary .slick-arrow::before {
  content: unset;
}

section#videoLibrary .VideoLibrary {
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
}
section#videoLibrary .current_video_iframe {
  border-radius: 5px;
}
section#videoLibrary .VideoLibrary .slick-track {
  display: flex;
}
section#videoLibrary .slick-initialized .slick-slide {
  min-width: 300px;
  margin: 5px;
  border-radius: 5px;
}
section#videoLibrary .video_list_iframe {
  border-radius: 5px;
}
section#videoLibrary .receptentab {
  margin: 3px 0;
}

.slick-slide div {
  width: 100%;
}

#instagram h2 .icon {
  width: 50px;
  height: 50px;
}
#instagram .button a {
  display: flex;
  align-items: center;
}

@media (max-width: 445px) {
  /* header section.openinghours { display: none !important;} */
  section#vacature .motivatie,
  section#vacature .jobFormWrap.send {
    max-width: 300px;
  }
  header section.openinghours .opening-hours-table tr td {
    font-size: 16px !important;
  }
  header section.openinghours .header h2 {
    padding: 20px 0 !important;
  }
}

section#news {
  height: calc(100vh - 57px);
  background-color: var(--grey);
  margin-top: 57px;
  overflow: auto;
  padding: 0 !important;
}
section#news .content {
  padding: 20px;
  margin: 0 auto;
  height: auto !important;
}
section#news .content h3 {
  text-align: left;
  font-family: "Verdana", Sans-serif;
  color: var(--main);
  font-weight: 300;
  font-size: 25px;
}

section#news .content h4 {
  color: var(--grey-80);
  text-align: left;
  font-family: "Verdana", Sans-serif;
  font-size: 22px;
  font-weight: 900;
  margin: 25px 0;
}
section#news .content p {
  text-align: justify;
  color: var(--grey-80);
  font-weight: 900;
  width: 100%;
  font-size: 100%;
  /* flex: 1; */
}
section#news .video {
  display: flex;
  justify-content: center;
  background: black;
  width: 100%;
  max-height: 650px;
  padding: 0 20px;
}
section#news .video video {
  max-width: 70%;
  width: 70%;
}
section#news .content.block {
  max-width: 100%;
}
section#news .content.block .container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
}
section#news .content.block .container span {
  display: flex;
  flex-direction: column;
}
section#news .content.block .container h4,
section#news .content.block .container h3 {
  margin: 0;
  align-self: flex-start;
}
/* section#news .content.block .container h3 {
  font-size: 25px;
} */
section#news .content.block .container h4 {
  margin-top: 10px;
}
section#news .content.block .container q {
  font-size: 18px;
  color: var(--main);
  font-weight: 900;
  position: relative;
  padding-left: 20px;
  margin: 20px 0;
}
section#news .content.block .container q::before {
  content: "";
  width: 10px;
  height: 100%;
  position: absolute;
  background-color: var(--grey-10);
  display: block;
  left: 0;
}
section#news .content.block figure {
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  line-height: 0;
  max-height: 400px;
}
section#news .content.block figure img {
  position: relative;
  top: 0;
  left: 0;
  border-radius: 5px;
  width: 100%;
}

@media (min-width: 800px) {
  section#news .content.block.one {
    display: flex;
    gap: 40px;
  }
  section#news .content.block.one figure {
    width: 40%;
  }
  section#news .content.block.one .container {
    width: 60%;
  }
}

@media (min-width: 1000px) {
  section#news .content {
    max-width: 70% !important;
  }
  section#news .content h3,
  section#news .content h4 {
    text-align: center;
  }
  section#news .content.block.two {
    display: flex;
    gap: 40px;
  }
  section#news .content.block.three {
    display: flex;
    gap: 40px;
    max-height: 450px;
  }
  section#news .content.block.three figure {
    width: 30%;
  }
  section#news .content.block.three .container {
    width: 70%;
  }
}

/* BROODJES */
section#broodjes {
  height: calc(100vh - 57px);
  background-color: var(--grey);
  margin-top: 57px;
  overflow: auto;
  padding: 30px 0 0 !important;
  font-family: "Convergence", Sans-serif;
}
section#broodjes h3 {
  color: #e31c19;
  font-size: 2em;
  text-align: center;
  font-family: "Convergence", Sans-serif;
  margin-bottom: 10px;
}
section#broodjes h5 {
  text-align: center;
}

section#broodjes .container {
  justify-content: center;
  margin-top: 50px;
}
section#broodjes .container aside {
  width: 50%;
  padding: 0 50px;
  max-width: 560px;
}
section#broodjes .container .item {
  margin: 20px 0;
}
section#broodjes .container .top-item {
  display: flex;
  margin-bottom: 10px;
}
section#broodjes .container .item-name,
section#broodjes .container .item-price {
  color: var(--grey-80);
  font-size: 1.2em;
  font-weight: 600;
}
section#broodjes .container .item-strip {
  height: 1px;
  flex: 1;
  align-self: center;
  background-color: var(--grey-80);
  margin: 0 30px;
}
section#broodjes .container .item p {
  /* white-space: nowrap; */
  font-size: 14px;
}
section#broodjes .info {
  margin-top: 20px;
  width: 100%;
  background-color: var(--main);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px 0;
  text-align: center;
  height: 250px;
  font-family: "Convergence", Sans-serif;
}
section#broodjes .info h4 {
  font-family: "Convergence", Sans-serif;
  font-size: 1.5em;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}
section#broodjes .info p {
  font-weight: 300;
  flex: 0;
  font-family: "Convergence", Sans-serif;
}
section#broodjes .info h4:last-child {
  flex: 1;
}

@media (max-width: 1110px) {
  section#broodjes .container .item {
    min-height: 100px;
  }
}
@media (max-width: 760px) {
  section#broodjes .container {
    flex-direction: column;
  }
  section#broodjes .container aside {
    width: 100%;
    max-width: 100%;
  }
  section#broodjes .container .item {
    min-height: unset;
  }
  section#broodjes .container .item:last-child {
    margin-bottom: 0 !important;
  }
}

/* TOPAANBIEDINGEN */
section#topaanbiedingen {
  height: calc(100vh - 57px);
  background-color: var(--grey);
  margin-top: 57px;
  overflow: auto;
  padding: 30px 0 0 !important;
}
section#topaanbiedingen .container {
  display: flex;
  gap: 20px;
  padding: 60px;
  justify-content: center;
}
section#topaanbiedingen .container figure {
  width: 50%;
  position: relative;
  max-width: 600px;
}
section#topaanbiedingen .container figure img {
  width: 100%;
}
@media (max-width: 1000px) {
  section#topaanbiedingen .container {
    flex-direction: column;
    align-items: center;
  }
  section#topaanbiedingen .container figure {
    width: 100%;
  }
}

/* TRAITEUR */
section#traiteur {
  height: calc(100vh - 57px);
  background-color: var(--grey);
  margin-top: 57px;
  overflow: auto;
  padding: 30px 0 0 !important;
  font-family: "Convergence", Sans-serif;
}
section#traiteur h3 {
  color: #e31c19;
  font-size: 2em;
  text-align: center;
  font-family: "Convergence", Sans-serif;
  margin-bottom: 10px;
}
section#traiteur .intro {
  text-align: center;
  font-family: "Convergence", Sans-serif;
  color: var(--grey-70);
}

section#traiteur .container {
  justify-content: center;
  margin-top: 50px;
}
section#traiteur .container aside {
  width: 50%;
  padding: 0 50px;
  max-width: 700px;
}
section#traiteur .container aside h4 {
  color: var(--grey-100);
  font-family: "Convergence", Sans-serif;
  /* font-family: "Helvetica", Sans-serif; */
  font-size: 1em;
  padding-top: 20px;
  text-transform: uppercase;
}

section#traiteur .container .item {
  margin: 20px 0;
}
section#traiteur .container .top-item {
  display: flex;
  margin-bottom: 10px;
}
section#traiteur .container .item-name,
section#traiteur .container .item-price {
  color: var(--grey-80);
  font-size: 1em;
  font-weight: 300;
}
section#traiteur .container .item-price {
  white-space: nowrap;
}
section#traiteur .container .item-strip {
  height: 1px;
  flex: 1;
  align-self: center;
  background-color: var(--grey-80);
  margin: 0 30px;
}
section#traiteur .container .item p {
  /* white-space: nowrap; */
  font-size: 14px;
}
section#traiteur .info {
  margin-top: 20px;
  width: 100%;
  background-color: var(--main);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px;
  text-align: center;
  font-family: "Convergence", Sans-serif;
}
section#traiteur .info h4 {
  font-family: "Convergence", Sans-serif;
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}
section#traiteur .info p {
  font-weight: 300;
  flex: 0;
  font-family: "Convergence", Sans-serif;
  margin-bottom: 50px;
}
section#traiteur .info h4:nth-child(3) {
  margin-bottom: 50px;
}
section#traiteur .info div {
  display: flex;
  padding: 0 50px;
  width: 100%;
  justify-content: center;
  gap: 50px;
}
section#traiteur .info div ul {
  width: 40%;
  text-align: left;
}
section#traiteur .info div ul li {
  margin: 10px 0;
}
@media (max-width: 760px) {
  section#traiteur .container {
    flex-direction: column;
  }
  section#traiteur .container aside {
    width: 100%;
    max-width: 100%;
  }
  section#traiteur .container .item {
    min-height: unset;
  }
  section#traiteur .container .item:last-child {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 650px) {
  section#traiteur .info div {
    flex-direction: column;
    padding: 0 10px;
    gap: 0;
  }
  section#traiteur .info div ul {
    width: 100%;
  }
}

/* BEREIDE GERECHTEN */
section#bereideGerechten {
  height: calc(100vh - 57px);
  background-color: var(--grey);
  margin-top: 57px;
  overflow: auto;
  padding: 30px 0 0 !important;
  font-family: "Convergence", Sans-serif;
}
section#bereideGerechten h3 {
  color: #e31c19;
  font-size: 2em;
  text-align: center;
  font-family: "Convergence", Sans-serif;
  margin-bottom: 10px;
}
section#bereideGerechten .intro {
  text-align: center;
  font-family: "Convergence", Sans-serif;
  color: var(--grey-70);
  padding: 30px;
}
section#bereideGerechten .container {
  background-color: white;
  padding: 50px 20px;
  gap: 40px;
}
section#bereideGerechten .container aside {
  width: 50%;
}

section#bereideGerechten .container aside h4 {
  color: var(--main);
  font-family: "Convergence", Sans-serif;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
}
section#bereideGerechten .container aside .icons {
  display: grid;
  grid-template-columns: 150px 150px 150px 150px;
  grid-template-rows: 120px 120px 120px;
  flex-wrap: wrap;
  padding: 50px 0 0;
  justify-content: center;
}
section#bereideGerechten .container aside .icons .item {
  width: 20%;
  min-width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
section#bereideGerechten .container aside .icons .item figure {
  height: 53px;
}
section#bereideGerechten .container aside .icons .item figure img {
  height: 100%;
}

section#bereideGerechten .container aside .accordion {
  width: 100%;
  padding-top: 80px;
  max-width: 600px;
}
section#bereideGerechten .accordion .accordionTitle.active {
  color: var(--main);
}

section#bereideGerechten .info {
  width: 100%;
  background-color: var(--main);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 20px;
  text-align: center;
  font-family: "Convergence", Sans-serif;
}
section#bereideGerechten .info h4 {
  font-family: "Convergence", Sans-serif;
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}
section#bereideGerechten .info p {
  font-weight: 300;
  flex: 0;
  font-family: "Convergence", Sans-serif;
  margin-bottom: 50px;
}

@media (max-width: 1150px) {
  section#bereideGerechten .container aside .icons {
    grid-template-columns: 150px 150px 150px;
  }
}
@media (max-width: 1000px) {
  section#bereideGerechten .container {
    flex-direction: column;
  }
  section#bereideGerechten .container aside {
    width: 100%;
    order: 1;
  }
  section#bereideGerechten .container aside:last-child {
    order: 0;
    margin-bottom: 30px;
  }
  section#bereideGerechten .container aside .icons {
    grid-template-columns: 150px 150px 150px 150px;
  }
  section#bereideGerechten .container aside .accordion {
    max-width: 100%;
  }
}
@media (max-width: 600px) {
  section#bereideGerechten .container aside .icons {
    grid-template-columns: 150px 150px 150px;
  }
}
@media (max-width: 450px) {
  section#bereideGerechten .container aside .icons {
    grid-template-columns: 150px 150px;
  }
}

/* SUPERPLANNER */
section#superplanner {
  height: calc(100vh - 57px);
  background-color: var(--grey);
  margin-top: 57px;
  overflow: auto;
  padding: 0 !important;
}

/* SUPERPLANNER */
section#winkelenBijOns {
  height: calc(100vh - 57px);
  background-color: var(--grey);
  margin-top: 57px;
  overflow: auto;
  padding: 0 !important;
  display: flex;
  padding-top: 30px !important;
  align-items: center;
  flex-direction: column;
}

section#winkelenBijOns video {
  width: 90%;
  margin: 0 auto;
  height: 80%;
  background-color: black;
}
section#winkelenBijOns h3 {
  color: #e31c19;
  font-size: 2em;
  text-align: center;
  font-family: "Convergence", Sans-serif;
  margin: 20px 0;
}
section#winkelenBijOns .content {
  overflow: visible;
  display: flex;
  gap: 50px;
  width: 100%;
  justify-content: center;
  padding: 0 50px 50px;
  height: auto;
}
section#winkelenBijOns .content p {
  width: 30%;
  text-align: justify;
  font-weight: 300;
  font-size: 100%;
}
section#winkelenBijOns .content p .highlight {
  color: var(--main);
  font-weight: 900;
}
section#winkelenBijOns .slick-slider {
  display: flex;
  width: 70%;
  margin-bottom: 100px;
}
section#winkelenBijOns .slick-list {
  width: 100%;
}
section#winkelenBijOns .slick-track {
  display: flex;
  gap: 10px;
}
/* .slick-initialized .slick-slide {
  width: 33% !important;
  max-width: 440px;
} */
section#winkelenBijOns .slick-track figure {
  width: 100%;
}
section#winkelenBijOns .slick-track figure img {
  width: 100%;
}
section#winkelenBijOns .slick-prev:before,
.slick-next:before {
  /* color: black; */
}
@media (max-width: 900px) {
  section#winkelenBijOns h3 {
    font-size: 1.5em;
    padding: 0 30px;
  }
  section#winkelenBijOns .content {
    flex-direction: column;
  }
  section#winkelenBijOns .content p {
    width: 100%;
  }
}
